<script>
import { mapGetters } from "vuex";
import BaseLoading from "@/components/BaseLoading.vue";
import Header from "@/layouts/Header.vue";
import Footer from "@/layouts/Footer.vue";

export default {
	data() {
		return {
			sticky: false,
		};
	},
	components: { Header, Footer, BaseLoading },
	computed: {
		...mapGetters({
			isLoading: "loading/isLoading",
		}),
	},
	methods: {
		stickyHeader() {
			if (window.innerWidth > 767) {
				this.sticky = this.$refs.layout?.scrollTop > 100;
			}
		},
	},
};
</script>

<template>
	<div ref="layout" class="admin layout bg-white" @scroll="stickyHeader">
		<Header ref="header" :sticky="sticky"></Header>
		<router-view />
		<BaseLoading v-if="isLoading"></BaseLoading>
		<Footer class="fixed bg-white"></Footer>
	</div>
</template>

<style lang="scss" scoped>
.layout{
	height: 100vh;
}
.main {
	width: 100%;
	max-width: 100%;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
}
.footer {
	border-top: 1px solid $gray;
}
</style>
