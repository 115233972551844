<script>
export default {
	props: {
		sticky: {
			required: false,
			type: Boolean,
		},
	},
	data() {
		return {
			displayMenu: false,
			items: [
				
				{
					name: "Reportes",
					link: "Colaboradores",
					subitems: [
						{
							name: "Colaboradoradores",
							link: "ReconocimientoColaboradores",
						},
						{
							name: "Principio Cultura",
							link: "ReconocimientoCultura",
						},
					],
				},
				{
					name: "Colaboradores",
					link: "Colaboradores",
					subitems: [
						{
							name: "Buscar",
							link: "Buscar",
						},
					],
				},
				{
					name: "Enviar",
					link: "Gestiones",
					subitems: [
						{
							name: "Reconocimientos",
							link: "ReconocimientosMasivos",
						},
					],
				},
				{
					name: "Ajustes",
					link: "Culturas",
					subitems: [
						{
							name: "Periodos",
							link: "Periodos",
						},
						{
							name: "Culturas",
							link: "Culturas",
						},
						{
							name: "Roles",
							link: "Roles",
						},
						{
							name: "Preguntas Frecuentes",
							link: "PreguntasFrecuentes",
						},
					],
				},
				{
					name: "Zonas",
					link: "Territorios",
					subitems: [
						{
							name: "Territorios",
							link: "Territorios",
						},
						{
							name: "Regiones",
							link: "Regiones",
						},
						{
							name: "Agencias",
							link: "Agencias",
						},
					],
				},
				{
					name: "Perfil",
					link: "Admin",
					subitems: [
						{
							name: "Votación",
							link: "Inicio",
						},
						{
							name: "Cerrar Sesión",
							link: "Logout",
						},
					],
				},
			],
			showDropdown: null,
		};
	},
	methods: {
		toggleMenu() {
			this.displayMenu = !this.displayMenu;
		},
		selectItem(item = null) {
			if (item) {
				!this.showDropdown || this.showDropdown != item.name
					? (this.showDropdown = item.name)
					: (this.showDropdown = null);
			} else {
				this.showDropdown = null;
			}
		},
	},
};
</script>

<template>
	<div
		class="header w-full md:flex h-12  fixed z-10 top-0 right-0 text-white shadow-lg"
	>
		<div class="z-10 h-full relative flex justify-center bg-white">
			<router-link :to="{ name: 'Metricas' }" class="h-full">
				<img
					class="h-full py-0 px-4 "
					src="@/assets/images/layouts/FOOTER/Logo_GE_2.png"
				/>
			</router-link>
			<font-awesome-icon
				@click="toggleMenu"
				class="cursor-pointer fixed right-0 h-6 w-6 m-3 box-border md:hidden green text-xl"
				icon="fa-solid fa-bars"
			/>
		</div>
		<nav
			:style="{ top: displayMenu ? '3rem' : '-100vh' }"
			class="w-full elektra-bg-red md:h-full absolute right-0 md:static ml-auto bg-green-light"
		>
			<ul class="flex h-full flex-col md:flex-row md:justify-end">
				<li
					v-for="item in items"
					:key="item.name"
					class="w-full h-full relative toplink border-b md:border-0 border-white"
					:class="
						item.name == 'Perfil'
							? 'avatar md:w-20'
							: `md: w-auto lg:w-1/${items.length}`
					"
					@click="selectItem(item)"
					@mouseleave="selectItem()"
				>
					<span
						class="cursor-pointer h-16 md:h-full py-3 px-4 text-md flex items-center justify-center"
						>{{ item.name != "Perfil" ? item.name : "" }}
						<font-awesome-icon
							class="text-3xl"
							v-if="item.name == 'Perfil'"
							icon="fa-solid fa-circle-user"
						/>
					</span>
					<ul
						v-if="item.subitems"
						class="dropdown bg-blue md:absolute w-full"
					>
						<li
							v-for="subitem in item.subitems"
							:key="subitem.name"
						>
							<router-link
								@click.native="toggleMenu"
								class="sublink px-4 flex items-center white"
								:class="
									showDropdown == item.name
										? 'h-14 text-md py-3 leading-6'
										: 'h-0 text-0'
								"
								:to="{ name: subitem.link }"
								>{{ subitem.name }}</router-link
							>
						</li>
					</ul>
				</li>
			</ul>
		</nav>
	</div>
</template>

<style lang="scss" scoped>
.text-0 {
	font-size: 0;
}

.toplink {
	@media (min-width: 768px) {
		transition: all 0.3s ease;
	}

	&:not(.avatar) {
		&:hover {
			background: $orange;
		}
		@media (min-width: 768px) {
			max-width: 180px;
		}
	}
}

//DROPDOWNS
.avatar {
	.dropdown {
		min-width: 180px;
		right: 0;
	}
}
.dropdown {
	min-width: 200px;
}
.sublink {
	&:hover {
		background-color: $black;
	}

	transition: all 0.3s ease;
}
</style>
